const isVideoUrl = (url) => {
  const videoExtensions = [
    "mp4",
    "mkv",
    "avi",
    "mov",
    "wmv",
    "flv",
    "webm",
    "ogg",
  ];
  const extension = url.split(".").pop();
  return videoExtensions.includes(extension.toLowerCase());
};

const isVideoFile = (file) => {
  const videoMimeTypes = [
    "video/mp4",
    "video/mkv",
    "video/avi",
    "video/mov",
    "video/webm",
    "video/ogg",
    "video/mpeg",
    "video/x-matroska",
  ];

  return videoMimeTypes.includes(file.type);
};

export { isVideoUrl, isVideoFile };
