import {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";
import { useUser } from "./userContext";
import {
  addItemsToCart,
  getCartItems,
  removeAllFromCart,
  removeItemFromCart,
  removeItemsFromCart,
} from "../api/cartApis";

const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const { user } = useUser();
  const [cartItems, setCartItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchCart = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      let fetchedItems = [];
      if (user) {
        const response = await getCartItems();
        if (response.data?.cartItems) {
          fetchedItems = response.data.cartItems;
        }
      } else {
        const localCartItems = localStorage.getItem("cartItems");
        if (localCartItems) {
          fetchedItems = JSON.parse(localCartItems);
        }
      }
      setCartItems(fetchedItems);
    } catch (error) {
      console.error("Error fetching cart items", error);
      setError("Failed to fetch cart items. Please try again.");
    } finally {
      setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    fetchCart();
  }, [user, fetchCart]);

  const addToCart = useCallback(
    async (item, quantity) => {
      setLoading(true);
      setError(null);
      try {
        if (user) {
          await addItemsToCart(item._id, quantity);
          await fetchCart();
        } else {
          const isItemInCart = cartItems.find(
            (cartItem) =>
              cartItem.productId && cartItem.productId._id === item._id
          );
          let updatedCart;
          if (isItemInCart) {
            updatedCart = cartItems.map((cartItem) =>
              cartItem.productId && cartItem.productId._id === item._id
                ? {
                    ...cartItem,
                    itemQuantity: cartItem.itemQuantity + quantity,
                  }
                : cartItem
            );
          } else {
            updatedCart = [...cartItems, { productId: item, itemQuantity: 1 }];
          }
          setCartItems(updatedCart);
          localStorage.setItem("cartItems", JSON.stringify(updatedCart));
        }
      } catch (error) {
        console.error("Error adding to cart", error);
        setError("Failed to add item to cart. Please try again.");
      } finally {
        setLoading(false);
      }
    },
    [user, cartItems, fetchCart]
  );

  const removeFromCart = useCallback(
    async (item) => {
      setLoading(true);
      setError(null);
      try {
        if (user) {
          await removeItemFromCart(item._id);
          await fetchCart();
        } else {
          const updatedCart = cartItems.filter(
            (cartItem) =>
              cartItem.productId && cartItem.productId._id !== item._id
          );
          setCartItems(updatedCart);
          localStorage.setItem("cartItems", JSON.stringify(updatedCart));
        }
      } catch (error) {
        console.error("Error removing from cart", error);
        setError("Failed to remove item from cart. Please try again.");
      } finally {
        setLoading(false);
      }
    },
    [user, cartItems, fetchCart]
  );

  const removeSelectedItemsFromCart = async (items) => {
    setLoading(true);
    setError(null);
    try {
      if (user) {
        await removeItemsFromCart(items);
        await fetchCart();
      } else {
        const idsToRemove = items
          .map((item) => item.productId && item.productId._id)
          .filter(Boolean);
        setCartItems(
          cartItems.filter(
            (cartItem) =>
              cartItem.productId &&
              !idsToRemove.includes(cartItem.productId._id)
          )
        );
      }
    } catch (error) {
      console.error("Error removing from cart", error);
      setError("Failed to remove item from cart. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const clearCart = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      if (user) {
        await removeAllFromCart();
        setCartItems([]);
      } else {
        setCartItems([]);
        localStorage.removeItem("cartItems");
      }
    } catch (error) {
      console.error("Error clearing cart", error);
      setError("Failed to clear cart. Please try again.");
    } finally {
      setLoading(false);
    }
  }, [user]);

  const itemInCart = (productId) => {
    return cartItems.find(
      (item) => item.productId && item.productId._id === productId
    );
  };

  const transferCartToDatabase = useCallback(async () => {
    if (user) {
      const localCartItems =
        JSON.parse(localStorage.getItem("cartItems")) || [];
      for (let item of localCartItems) {
        if (item.productId) {
          await addItemsToCart(item.productId._id, item.itemQuantity);
        }
      }
      localStorage.removeItem("cartItems");
      fetchCart();
    }
  }, [user, fetchCart]);

  useEffect(() => {
    if (user) {
      transferCartToDatabase();
    }
  }, [user, transferCartToDatabase]);

  return (
    <CartContext.Provider
      value={{
        cartItems,
        addToCart,
        removeFromCart,
        removeSelectedItemsFromCart,
        clearCart,
        itemInCart,
        loading,
        error,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export function useCart() {
  return useContext(CartContext);
}
