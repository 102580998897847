import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { getUserDetails } from "../api/userApis";
import { useAuth } from "./authContext";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { auth, logout } = useAuth();
  const userFetched = useRef(false);

  const fetchUser = useCallback(
    async (accessToken) => {
      setIsLoading(true);
      try {
        const response = await getUserDetails(accessToken);
        if (response?.status) {
          setUser((prev) => {
            return { ...prev, ...response.data };
          });
          console.log("User data fetched:", response.data);
        }
      } catch (error) {
        console.error("Error fetching user:", error);
        if (error.response?.status === 401) {
          // Unauthorized, logout the user
          logout();
        }
      } finally {
        setIsLoading(false);
      }
    },
    [logout]
  );

  useEffect(() => {
    const { accessToken } = auth;
    if (!userFetched.current && accessToken) {
      fetchUser(accessToken);
      userFetched.current = true;
    } else if (!accessToken) {
      setUser(null);
      setIsLoading(false);
      userFetched.current = false;
    }
  }, [auth, fetchUser]);

  return (
    <UserContext.Provider value={{ user, isLoading }}>
      {children}
    </UserContext.Provider>
  );
};

export function useUser() {
  return useContext(UserContext);
}
