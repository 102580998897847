import { getCookie } from "../utils/cookie";
import axiosInstance from "./axiosInstance";

const getWishlistItems = () => {
  const token = getCookie("accessToken");
  return axiosInstance.get("/getWishlistItems", {
    headers: {
      authorization: "Bearer " + token,
    },
  });
};

const addItemsToWishlist = (productId) => {
  const token = getCookie("accessToken");
  return axiosInstance.post(
    "/addToWishlist",
    { productId },
    {
      headers: {
        authorization: "Bearer " + token,
      },
    }
  );
};

const removeItemsFromWishlist = (productId) => {
  const token = getCookie("accessToken");
  return axiosInstance.post(
    "/deleteFromWishlist",
    { productId },
    {
      headers: {
        authorization: "Bearer " + token,
      },
    }
  );
};

export { getWishlistItems, addItemsToWishlist, removeItemsFromWishlist };
