import { createContext, useContext, useEffect, useState } from "react";
import { useUser } from "./userContext";
import { useToast } from "./toastContext";
import {
  addNewAddress,
  deleteAddress,
  getAddresses,
  setAddressAsDefault,
  updateExistingAddress,
} from "../api/addressApis";

const AddressContext = createContext();

export const AddressProvider = ({ children }) => {
  const { user } = useUser();
  const toast = useToast();
  const [addresses, setAddresses] = useState([]);
  const [defaultAddress, setDefaultAddress] = useState(null);
  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   console.log("The addresses: " + JSON.stringify(addresses));
  //   console.log("The default addresses: " + JSON.stringify(defaultAddress));
  // }, [addresses, defaultAddress]);

  const fetchAddresses = async () => {
    setLoading(true);

    try {
      const response = await getAddresses();

      if (response?.status) {
        if (!response?.data?.addressDetails) return;
        setAddresses(response.data?.addressDetails);
        console.log(response.data);
        const defaultAddr = response.data?.addressDetails?.find(
          (addr) => addr.isDefault
        );
        setDefaultAddress(defaultAddr || null);
      }
    } catch (error) {
      console.error("Error fetching addresses", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user) {
      fetchAddresses();
    }
  }, [user]);

  const addAddress = async (addressData) => {
    if (!user) {
      toast.error("You need to be logged in to create address.");
      return;
    }

    try {
      const response = await addNewAddress(addressData);

      if (response?.status) {
        fetchAddresses();
        toast.success(response?.data?.message);
      }
    } catch (error) {
      console.error("Error adding address", error);
    }
  };

  const updateAddress = async (addressId, addressData) => {
    if (!user) {
      toast.error("You need to be logged in to update address.");
      return;
    }

    try {
      const response = await updateExistingAddress(addressId, addressData);

      if (response?.status) {
        fetchAddresses();
        toast.success(response?.data?.message);
      }
    } catch (error) {
      console.error("Error updating address", error);
    }
  };

  const removeAddress = async (addressId) => {
    if (!user) {
      toast.error("You need to be logged in to use the wishlist.");
      return;
    }

    try {
      const response = await deleteAddress(addressId);

      if (response?.status) {
        fetchAddresses();
        toast.success(response?.data?.message);
      }
    } catch (error) {
      console.error("Error deleting address", error);
    }
  };

  const setDefault = async (addressId) => {
    if (!user) {
      toast.error("You need to be logged in to use the wishlist.");
      return;
    }

    try {
      const response = await setAddressAsDefault(addressId);

      if (response?.status) {
        fetchAddresses();
        toast.success(response?.data?.message);
      }
    } catch (error) {
      console.error("Error setting address as default", error);
    }
  };

  return (
    <AddressContext.Provider
      value={{
        addresses,
        defaultAddress,
        addAddress,
        updateAddress,
        removeAddress,
        setDefault,
        loading,
      }}
    >
      {children}
    </AddressContext.Provider>
  );
};

export function useAddress() {
  return useContext(AddressContext);
}
