import InputBox from "../../components/InputBox";
import Logo from "../../components/Logo";
import Socials from "./Socials";
import TextareaBox from "../../components/TextareaBox";
import Button from "../../components/Button";
import useForm from "../../hooks/useForm";
import Contacts from "./Contacts";
import contactBg from "../../assets/images/contact us.png";
import { FiSmartphone, FiUser } from "react-icons/fi";
import { BiEnvelope } from "react-icons/bi";
import { useContactUs } from "../../services/userServices";
import { useToast } from "../../contexts/toastContext";
import { isEmailValid, isPhoneValid } from "../../utils/validation";

const ContactUs = () => {
  const contactMutation = useContactUs();
  const toast = useToast();

  const onSubmitHandler = (formData) => {
    const { name, email, phone, issue } = formData;

    //Client side validations
    const warnings = [];

    if (!name) {
      warnings.push("Please enter a name");
    }
    if (!isEmailValid(email).success) {
      warnings.push(isEmailValid(email).message);
    }
    if (!isPhoneValid(phone).success) {
      warnings.push(isPhoneValid(phone).message);
    }
    if (!issue) {
      warnings.push("Please write an issue");
    }

    // If there are errors, display them and return
    if (warnings.length > 0) {
      warnings.forEach((warning) => toast.error(warning));
      return;
    }

    contactMutation.mutate(
      { name, email, phone, issue },
      {
        onSuccess: () => {
          clearForm();
          toast.success("Thank you for contacting us");
        },
        onError: (error) => {
          console.error(error);
          toast.error(error?.response?.data?.message || error.message);
        },
      }
    );
  };

  const { formData, handleChange, handleSubmit, clearForm } = useForm(
    {},
    onSubmitHandler
  );

  return (
    <main className="container max-w-full py-8 max-sm:p-4">
      <div className="max-sm:relative w-[720px] max-w-full mx-auto border border-secondary rounded flex max-sm:flex-col-reverse overflow-hidden">
        <section className="LEFT-SECTION sm:relative w-60 max-sm:w-full flex flex-col justify-end flex-shrink-0">
          <div className="z-[1] max-sm:backdrop-blur">
            <div className="p-6 space-y-4 sm:backdrop-blur max-sm:w-80 max-w-full max-sm:mx-auto">
              <div className="w-full">
                <Logo />
              </div>
              <Contacts />
              <p className="text-xs">
                Your satisfaction is our priority. Reach out to us for any
                assistance
              </p>
              <Socials />
            </div>
          </div>

          <img
            src={contactBg}
            alt=""
            className="absolute size-full object-cover z-0"
          />
        </section>

        <section className="RIGHT-SECTION grow z-[1]">
          <div className="HEADING p-8">
            <h2 className="text-xl font-semibold">GET IN TOUCH</h2>
            <p className="text-sm text-primary">
              24/7 We will answer your questions and problems
            </p>
          </div>
          <div className="BODY py-8 px-4">
            <form
              onSubmit={handleSubmit}
              className="w-80 max-w-full mx-auto space-y-4"
            >
              <div className="relative">
                <InputBox
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="name"
                  className="p-3 pl-12 border border-primary rounded-md text-xs font-medium"
                />
                <FiUser className="absolute top-1/2 -translate-y-1/2 left-4" />
              </div>
              <div className="relative">
                <InputBox
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="email id"
                  className="p-3 pl-12 border border-primary rounded-md text-xs font-medium"
                />
                <BiEnvelope className="absolute top-1/2 -translate-y-1/2 left-4" />
              </div>
              <div className="relative">
                <InputBox
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  placeholder="phone"
                  className="p-3 pl-12 border border-primary rounded-md text-xs font-medium"
                />
                <FiSmartphone className="absolute top-1/2 -translate-y-1/2 left-4" />
              </div>
              <TextareaBox
                rows={6}
                name="issue"
                value={formData.issue}
                onChange={handleChange}
                placeholder="Describe your issue"
                className="p-4 border border-primary rounded-md text-xs font-medium resize-none"
              />

              <Button className="w-full px-4 py-2 rounded-md">SEND</Button>
            </form>
          </div>
        </section>
      </div>
    </main>
  );
};

export default ContactUs;
