import PostList from "./PostList";

const Fashion = () => {
  return (
    <main className="container max-w-full">
      <PostList />
    </main>
  );
};

export default Fashion;
