import axiosInstance from "./axiosInstance";

const getAllCoupons = async () => {
  const response = await axiosInstance.get("/getAllCoupons");
  return response.data?.couponsDetails;
};

const getCouponById = (couponId) => {
  return axiosInstance.get(`/getTheCoupon/${couponId}`);
};

const applyCouponAPI = (code, orderAmount) => {
  return axiosInstance.post("/applyCoupon", { code, orderAmount });
};

const getAllFlashDeals = async () => {
  const response = await axiosInstance.get("/getAllFlashDealOnUser");
  return response.data?.flashDealDetails;
};

const getFlashDealById = async (dealId) => {
  const response = await axiosInstance.get(`/getFlashDealByIdOnUser/${dealId}`);
  return response.data?.dealDetails;
};

const getAllFeaturedDeals = async () => {
  const response = await axiosInstance.get("/getAllFeaturedDealsOnUser");
  return response.data?.featuredDealsDetails;
};

const getFeaturedDealById = async (dealId) => {
  const response = await axiosInstance.get(
    `/getFeaturedDealByIdOnUser/${dealId}`
  );
  return response.data?.dealDetails;
};

const getAllDealOfTheDay = async () => {
  const response = await axiosInstance.get("/getDealOfTheDayOnUser");
  return response.data?.dealOfTheDayDetails;
};

const getDealOfTheDayById = async (dealId) => {
  const response = await axiosInstance.get(
    `/getDEalOfTheDayByIdOnUser/${dealId}`
  );
  return response.data?.dealDetails;
};

export {
  getAllCoupons,
  getCouponById,
  applyCouponAPI,
  getAllFlashDeals,
  getFlashDealById,
  getAllFeaturedDeals,
  getFeaturedDealById,
  getAllDealOfTheDay,
  getDealOfTheDayById,
};
