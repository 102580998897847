import { useQuery } from "@tanstack/react-query";
import {
  getAllBrands,
  getAllFeaturedBrands,
  getAllTopSellingBrands,
  getAllUtsavFeaturedBrands,
  getBrand,
  getBrandsBasedOnCategory,
  getBrandsBasedOnProductType,
} from "../api/brandApis";

const useBrands = () => {
  return useQuery({
    queryKey: ["brands"],
    queryFn: getAllBrands,
    staleTime: 1000 * 60 * 5,
  });
};

const useFeaturedBrands = () => {
  return useQuery({
    queryKey: ["brands", "featured"],
    queryFn: getAllFeaturedBrands,
    staleTime: 1000 * 60 * 5,
  });
};

const useUtsavFeaturedBrands = () => {
  return useQuery({
    queryKey: ["brands", "featured", "utsav"],
    queryFn: getAllUtsavFeaturedBrands,
    staleTime: 1000 * 60 * 5,
  });
};

const useBrandsBasedOnCategory = (categoryId) => {
  return useQuery({
    queryKey: ["brands", categoryId],
    queryFn: () => getBrandsBasedOnCategory(categoryId),
    staleTime: 1000 * 60 * 5,
    enabled: !!categoryId,
  });
};

const useBrandsBasedOnProductType = (productTypeId) => {
  return useQuery({
    queryKey: ["brands", productTypeId],
    queryFn: () => getBrandsBasedOnProductType(productTypeId),
    staleTime: 1000 * 60 * 5,
    enabled: !!productTypeId,
  });
};

const useTopSellingBrands = () => {
  return useQuery({
    queryKey: ["brands", "top selling"],
    queryFn: getAllTopSellingBrands,
    staleTime: 1000 * 60 * 5,
  });
};

const useBrandById = (brandId) => {
  return useQuery({
    queryKey: ["brand", brandId],
    queryFn: () => getBrand(brandId),
    staleTime: 1000 * 60 * 5,
    enabled: !!brandId,
  });
};

export {
  useBrands,
  useFeaturedBrands,
  useUtsavFeaturedBrands,
  useBrandsBasedOnCategory,
  useBrandsBasedOnProductType,
  useTopSellingBrands,
  useBrandById,
};
