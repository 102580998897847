import { getCookie } from "../utils/cookie";
import axiosInstance from "./axiosInstance";

const getCartItems = () => {
  const token = getCookie("accessToken");
  return axiosInstance.get("/getCartItems", {
    headers: {
      authorization: "Bearer " + token,
    },
  });
};

const addItemsToCart = (productId, itemQuantity) => {
  const token = getCookie("accessToken");
  return axiosInstance.post(
    "/addToCart",
    { productId, itemQuantity },
    {
      headers: {
        authorization: "Bearer " + token,
      },
    }
  );
};

const removeItemFromCart = (productId) => {
  const token = getCookie("accessToken");
  return axiosInstance.post(
    "/deleteFromCart",
    { productId },
    {
      headers: {
        authorization: "Bearer " + token,
      },
    }
  );
};

const removeItemsFromCart = (productIdList) => {
  return axiosInstance.post("/removeFromCart", { productIdList });
};

const removeAllFromCart = () => {
  const token = getCookie("accessToken");
  return axiosInstance.get("/clearCart", {
    headers: {
      authorization: "Bearer " + token,
    },
  });
};

export {
  getCartItems,
  addItemsToCart,
  removeItemFromCart,
  removeItemsFromCart,
  removeAllFromCart,
};
