import loadingGif from "../assets/images/Loading.gif";

const Loader = () => {
  return (
    <main className="LOADER fixed inset-0 bg-white z-10 flex justify-center items-center">
      <img
        src={loadingGif}
        alt="Loading"
        loading="lazy"
        className="w-80 max-md:w-60"
      />
    </main>
  );
};

export default Loader;
