import { Children } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  EffectFade,
} from "swiper/modules";
import "swiper/css/bundle";

const Carousel = ({
  children,
  spaceBetween = 30,
  slidesPerView = 1,
  slideWidth,
  navigation = false,
  pagination = false,
  paginationBelow = false,
  scrollbar = false,
  autoplay = false,
  delay = 3000,
  loop = false,
  effect = "slide",
  speed = 300,
  allowTouchMove = false,
  ...props
}) => {
  const swiperProps = {
    modules: [Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectFade],
    spaceBetween,
    navigation: navigation ? {} : false,
    pagination:
      pagination && paginationBelow
        ? { clickable: true, el: ".swiper-pagination" }
        : pagination
        ? { clickable: true }
        : false,
    scrollbar: scrollbar ? { draggable: true } : false,
    autoplay: autoplay ? { delay } : false,
    loop,
    effect,
    speed,
    slidesPerView: slideWidth ? "auto" : slidesPerView,
    allowTouchMove: allowTouchMove,
    ...props,
  };

  return (
    <div className="relative">
      <Swiper {...swiperProps}>
        {Children.map(children, (child, index) => (
          <SwiperSlide
            key={index}
            style={slideWidth ? { width: `${slideWidth}px` } : {}}
          >
            {child}
          </SwiperSlide>
        ))}
      </Swiper>

      {pagination && paginationBelow && (
        <div
          className="swiper-pagination pagination-below"
          style={{
            position: "absolute",
            bottom: "-24px",
            left: "0",
            right: "0",
            textAlign: "center",
            zIndex: -1,
          }}
        />
      )}
    </div>
  );
};

export default Carousel;
