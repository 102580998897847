import {
  MdLocalPhone,
  MdMailOutline,
  MdOutlineLocationOn,
} from "react-icons/md";

const Contacts = () => {
  return (
    <table>
      <tr>
        <th className="align-top pr-2">
          <span className="inline-block">
            <MdOutlineLocationOn size={12} />
          </span>
        </th>
        <td>
          <p className="text-xs leading-tight pt-1">
            I-Space, DH-6/35, Opp Unitech Gate No. 2 Rajarahat, New Town, DH
            Block, Kolkata, West Bengal 700156
          </p>
        </td>
      </tr>
      <tr>
        <th className="pr-2">
          <span className="inline-block">
            <MdLocalPhone size={12} />
          </span>
        </th>
        <td>
          <a
            href="tel:+917595878899"
            target="_blank"
            rel="noopener noreferrer"
            className="text-xs leading-loose align-top"
          >
            7595878899
          </a>
        </td>
      </tr>
      <tr>
        <th className="pr-2">
          <span className="inline-block">
            <MdMailOutline size={12} />
          </span>
        </th>
        <td>
          <a
            href="mailto:info@finafid.com"
            target="_blank"
            rel="noopener noreferrer"
            className="text-xs leading-loose align-top"
          >
            info@finafid.com
          </a>
        </td>
      </tr>
    </table>
  );
};

export default Contacts;
