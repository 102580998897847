import { createContext, useContext, useState } from "react";

export const SearchHistoryContext = createContext();

export const SearchHistoryProvider = ({ children }) => {
  const [previousSearches, setPreviousSearches] = useState(() => {
    const storedSearches = localStorage.getItem("previousSearches");
    return storedSearches ? JSON.parse(storedSearches) : [];
  });

  const addSearch = (query) => {
    const updatedSearches = [
      query,
      ...previousSearches.filter((item) => item !== query),
    ].slice(0, 5);
    setPreviousSearches(updatedSearches);
    localStorage.setItem("previousSearches", JSON.stringify(updatedSearches));
  };

  return (
    <SearchHistoryContext.Provider value={{ previousSearches, addSearch }}>
      {children}
    </SearchHistoryContext.Provider>
  );
};

export const useSearchHistory = () => {
  return useContext(SearchHistoryContext);
};
