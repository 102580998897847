import { AnimatePresence, motion } from "framer-motion";
import { useSidebar } from "../contexts/sidebarContext";
import { createPortal } from "react-dom";

const variants = {
  appear: {
    opacity: 0,
    x: "100%",
    transition: {
      type: "spring",
      stiffness: 400,
      damping: 60,
    },
  },
  open: {
    opacity: 1,
    x: 0,
    transition: {
      type: "spring",
      stiffness: 400,
      damping: 60,
    },
  },
  closed: {
    opacity: 0,
    x: "100%",
    transition: {
      type: "spring",
      stiffness: 400,
      damping: 40,
    },
  },
};

const backdropVariants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

const swipeConfidenceThreshold = 10000;
const swipePower = (offset, velocity) => {
  return Math.abs(offset) * velocity;
};

const Backdrop = ({ children, onClick }) => {
  return (
    <motion.div
      variants={backdropVariants}
      initial="initial"
      animate="animate"
      exit="exit"
      onClick={onClick}
      className="SIDEBAR-BACKDROP fixed z-50 bg-black bg-opacity-80 inset-0 overflow-hidden"
    >
      {children}
    </motion.div>
  );
};

const Sidebar = () => {
  const { closeSidebar, content, isOpen } = useSidebar();

  return createPortal(
    <AnimatePresence initial={false}>
      {isOpen && (
        <Backdrop onClick={closeSidebar}>
          <motion.div
            initial="appear"
            animate="open"
            exit="closed"
            variants={variants}
            drag="x"
            dragConstraints={{ left: 0, right: 0 }}
            dragElastic={{ left: 0, right: 1 }}
            dragMomentum={false}
            dragTransition={{ delay: -0.5 }}
            onDragEnd={(e, { offset, velocity }) => {
              const swipe = swipePower(offset.x, velocity.x);
              const isRightDirection = offset.x > 45 && velocity.x >= 0;
              if (swipe > swipeConfidenceThreshold || isRightDirection) {
                closeSidebar();
              }
            }}
            onClick={(e) => e.stopPropagation()}
            className="h-svh max-w-96 w-full absolute top-0 right-0"
          >
            {content}
          </motion.div>
        </Backdrop>
      )}
    </AnimatePresence>,
    document.getElementById("sidebar")
  );
};

export default Sidebar;
