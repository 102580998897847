import Post from "./Post";
import { useFashionBlogs } from "../../services/fashionServices";
import Masonry from "react-masonry-css";

const breakpointColumnsObj = {
  default: 3,
  1100: 3,
  700: 2,
  500: 1,
};

const PostList = () => {
  const { data: blogs = [], isLoading, error } = useFashionBlogs();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error.message}</div>;
  }

  return (
    <Masonry
      breakpointCols={breakpointColumnsObj}
      className="my-8 flex w-auto space-x-4 max-lg:space-x-2"
      columnClassName="space-y-4 max-lg:space-y-2"
    >
      {blogs.map((blog) => (
        <Post key={blog._id} post={blog} />
      ))}
    </Masonry>
  );
};

export default PostList;
