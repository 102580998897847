import { PLATFORM_SOCIALS } from "../../utils/Constants";

const Socials = () => {
  return (
    <div className="SOCIALS-SECTION flex sm:justify-center">
      <div className="flex gap-1">
        {PLATFORM_SOCIALS.map((profile, index) => (
          <a
            key={index}
            href={profile.url}
            target="_blank"
            rel="noreferrer"
            className="p-1 text-white text-sm rounded-full"
            style={{ backgroundColor: profile.accent }}
          >
            {profile.icon}
          </a>
        ))}
      </div>
    </div>
  );
};

export default Socials;
