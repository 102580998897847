// import { FaRegBookmark } from "react-icons/fa";
// import Button from "../../components/Button";
import Carousel from "../../components/Carousel";
import Slider from "../../components/Slider";
// import { IoMdHeartEmpty } from "react-icons/io";
import { LuBadge } from "react-icons/lu";
import { FiShoppingBag } from "react-icons/fi";
import { timeAgo } from "../../utils/dateUtils";
import { isVideoUrl } from "../../utils/fileUtils";

const Post = ({ post }) => {
  return (
    <div className="rounded-md border border-secondary overflow-hidden">
      <div className="HEAD flex justify-between p-2">
        <div className="HEAD-LEFT flex items-center gap-4">
          <div className="size-12 rounded-full overflow-hidden flex-shrink-0">
            <img src={post.userLogo} alt={post.userName} className="" />
          </div>
          <div>
            <h2 className="text-sm font-medium">{post.userName}</h2>
            <p className="text-sm text-primary">{timeAgo(post.createdAt)}</p>
          </div>
          {/* <Button reverse className="h-max px-3 rounded-full">
            follow
          </Button> */}
        </div>

        {/* <button>
          <FaRegBookmark />
        </button> */}
      </div>

      <div className="BODY">
        <Slider dots navigation>
          {post.logoUrls?.map((post, index) => (
            <div
              key={index}
              className="flex w-full items-center justify-center"
            >
              {isVideoUrl(post) ? (
                <video
                  controls
                  controlsList="nodownload"
                  autoPlay
                  loop
                  className="h-max w-full object-contain object-center"
                >
                  <source src={post} type="video/mp4" />
                  <source src={post} type="video/mkv" />
                  <source src={post} type="video/avi" />
                  <source src={post} type="video/webm" />
                  <source src={post} type="video/ogg" />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <img
                  src={post}
                  alt="upload"
                  className="h-max w-full object-contain object-center"
                />
              )}
            </div>
          ))}
        </Slider>
      </div>

      {/* <div className="FOOT p-2 space-y-2">
        <div className="INTERACTION flex justify-between">
          <div className="LIKE-BUTTON flex items-center gap-1">
            <button>
              <IoMdHeartEmpty size={20} />
            </button>
            <span className="text-sm font-medium">1.0K</span>
          </div>

          <button>
            <LuShare2 size={18} />
          </button>
        </div>

        <p className="text-sm">{post.caption}</p>
        <p className="text-sm font-medium">#offer #festive #fashion</p>
      </div> */}

      <p className="text-sm p-2">{post.caption}</p>

      <div className="SUGGESTED-PRODUCTS p-2 flex items-center gap-4">
        <button className="flex w-max flex-col items-center flex-shrink-0">
          <div className="relative">
            <LuBadge size={40} strokeWidth={1} className="text-primeRed" />
            <span className="absolute inset-0 flex items-center justify-center text-primeRed">
              <FiShoppingBag strokeWidth={2.5} />
            </span>
          </div>
          <span className="text-xs font-medium text-primeRed">Shop All</span>
        </button>

        <div className="overflow-hidden">
          <Carousel slideWidth={240} spaceBetween={10} allowTouchMove>
            {post.productList?.map((product) =>
              product ? (
                <div
                  key={product._id}
                  className="PRODUCT-BOX w-60 flex border rounded overflow-hidden"
                >
                  <div className="size-16">
                    <img
                      src={product.images[0]}
                      alt={product.name || product._id}
                      className="size-full object-contain object-center"
                    />
                  </div>
                  <div className="text-xs p-2">
                    <h3 className="font-medium truncate">{product?.name}</h3>
                    {product?.attributes &&
                      Object.keys(product?.attributes)
                        .slice(0, 3)
                        .map((key) => (
                          <p key={key} className="capitalize">
                            {key}: {product?.attributes[key]}
                          </p>
                        ))}
                    <div className="flex items-center gap-1">
                      <p className="text-primary line-through font-medium leading-none">
                        ₹ {Math.round(product?.unitPrice)}
                      </p>
                      <p className="text-base text-success font-semibold leading-none">
                        ₹ {Math.round(product?.sellingPrice)}
                      </p>
                      {product?.discount > 0 && (
                        <p className="text-sm font-medium text-blue-600 leading-none">
                          {product?.discountType === "percent"
                            ? `${product.discount}%`
                            : `₹${product.discount} OFF`}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <p>Invalid Product</p>
              )
            )}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default Post;
