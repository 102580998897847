import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { addWalletBalance, getWalletBalance } from "../api/walletApis";
import { useToast } from "./toastContext";
import { useUser } from "./userContext";

const WalletContext = createContext();

export const WalletProvider = ({ children }) => {
  const [balance, setBalance] = useState(0);
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { user } = useUser();
  const toast = useToast();

  const getBalance = useCallback(async () => {
    if (!user) return;

    setLoading(true);
    setError(null);
    try {
      const response = await getWalletBalance();

      if (response?.status) {
        setBalance(response.data.walletDetails.balance);
        setTransactions(response.data.walletDetails.transactions);
      }
    } catch (error) {
      console.error(error.message);
      setError(error.message);
      toast.error("Failed to fetch wallet balance.");
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [user]);

  const addBalance = useCallback(
    async (amount, description) => {
      if (!user) return;

      try {
        const response = await addWalletBalance(Number(amount), description);

        if (response?.status) {
          toast.success(response.data.message);
          getBalance();
        }
      } catch (error) {
        console.error(error.message);
        setError(error.message);
        toast.error("Failed to add balance.");
      }
    },
    // eslint-disable-next-line
    [user, getBalance]
  );

  useEffect(() => {
    if (user) getBalance();
  }, [user, getBalance]);

  return (
    <WalletContext.Provider
      value={{
        balance,
        transactions,
        addBalance,
        getBalance,
        loading,
        error,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};

export const useWallet = () => {
  return useContext(WalletContext);
};
