import { useMutation, useQuery } from "@tanstack/react-query";
import { contactUs, generateReferralCode } from "../api/userApis";

const useReferral = () => {
  return useQuery({
    queryKey: ["referralCode"],
    queryFn: generateReferralCode,
    staleTime: 1000 * 60 * 5,
  });
};

const useContactUs = () => {
  return useMutation({
    mutationFn: contactUs,
  });
};

export { useReferral, useContactUs };
