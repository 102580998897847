import { FiSearch } from "react-icons/fi";
import { Link } from "react-router-dom";
import { useMemo, useRef, useState } from "react";
import { useBrands, useFeaturedBrands } from "../services/brandService";
import PropTypes from "prop-types";
import Loading from "./Loading";
import Skeleton from "./Skeleton";

const Tab = ({ children, selected, onClick }) => (
  <button
    onClick={onClick}
    className={`uppercase w-28 h-6 text-xs font-semibold rounded-sm flex justify-center items-center hover:bg-primeRed transition-colors duration-100 ease-in ${
      selected ? "bg-primeRed" : "bg-mainGray"
    }`}
  >
    {children}
  </button>
);

const SearchBar = ({ value, onChange }) => (
  <div className="BRAND-SEARCH p-4">
    <form onSubmit={(e) => e.preventDefault()} className="relative">
      <input
        type="search"
        id="brand-search"
        value={value}
        onChange={onChange}
        placeholder="Search Brands"
        className="mr-4 w-60 border border-primary outline-none text-xs py-1 pl-2 pr-4 rounded-tl-lg rounded-bl-lg"
      />
      <button className="p-1 border border-primary rounded-full absolute right-0 top-[2.7px] bg-white">
        <FiSearch size={18} />
      </button>
    </form>
  </div>
);

const AlphabetNavigation = ({ alphabetList, scrollToHeading }) => (
  <div className="ALPHABETS flex flex-col justify-between absolute top-14 bottom-1 right-2">
    {alphabetList.map((alphabet, index) => (
      <button
        key={index}
        onMouseEnter={() =>
          scrollToHeading(`brand-heading-${alphabet.toLowerCase()}`)
        }
      >
        <span className="text-xs text-primary hover:text-black hover:font-medium size-3 flex justify-center items-center">
          {alphabet}
        </span>
      </button>
    ))}
  </div>
);

const BrandList = () => {
  const { data: brands = [], isLoading: brandsLoading } = useBrands();
  const { data: featuredBrands = [], isLoading: featuredBrandsLoading } =
    useFeaturedBrands();
  const [searchedBrandName, setSearchedBrandName] = useState("");
  const brandListRef = useRef(null);

  const alphabetList = useMemo(() => {
    const alphabets = ["★"];
    for (let i = 65; i <= 90; i++) {
      alphabets.push(String.fromCharCode(i));
    }
    return alphabets;
  }, []);

  const handleSearch = (e) => {
    setSearchedBrandName(e.target.value);
  };

  const listOfBrands = useMemo(() => {
    const filteredBrands = brands
      .filter((brand) =>
        brand.name.toLowerCase().includes(searchedBrandName.toLowerCase())
      )
      .sort((a, b) => a.name.localeCompare(b.name));

    return filteredBrands.reduce((acc, brand) => {
      const firstLetter = brand.name[0].toUpperCase();
      const key = /[A-Z]/.test(firstLetter) ? firstLetter : "★";
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(brand);
      return acc;
    }, {});
  }, [brands, searchedBrandName]);

  const scrollToHeading = (id) => {
    const element = document.getElementById(id);
    const container = brandListRef.current;
    if (element && container) {
      container.scrollTo({
        top: element.offsetTop - container.offsetTop,
        behavior: "instant",
      });
    }
  };

  return (
    <div className="h-120 flex bg-white shadow-md font-normal">
      <div className="LEFT bg-white flex flex-col relative w-70">
        <SearchBar value={searchedBrandName} onChange={handleSearch} />
        <div
          ref={brandListRef}
          className="no-scroll px-4 pb-2 mr-6 overflow-y-auto space-y-2"
        >
          {brandsLoading ? (
            <ul className="min-h-full space-y-2">
              <div className="w-20 h-3 rounded my-4 overflow-hidden">
                <Skeleton />
              </div>
              {Array.from({ length: 10 }).map((_, index) => (
                <li key={index} className="w-30 h-2 rounded overflow-hidden">
                  <Skeleton />
                </li>
              ))}
            </ul>
          ) : (
            Object.keys(listOfBrands).map((key) => (
              <ul key={key} className="min-h-full space-y-1">
                <h4
                  id={`brand-heading-${key.toLowerCase()}`}
                  className="text-lg"
                >
                  {key}
                </h4>
                {listOfBrands[key].map((brand) => (
                  <li
                    key={brand._id}
                    className="text-xs text-secondary hover:text-black transition-colors duration-150 ease-in-out"
                  >
                    <Link to={`/brand/${brand._id}`}>{brand.name}</Link>
                  </li>
                ))}
              </ul>
            ))
          )}
        </div>
        <AlphabetNavigation
          alphabetList={alphabetList}
          scrollToHeading={scrollToHeading}
        />
      </div>

      <div className="RIGHT relative bg-lightGray w-[600px] max-lg:w-[520px]">
        {featuredBrandsLoading ? (
          <Loading />
        ) : (
          <ul className="BRAND-LIST size-full p-8 grid grid-cols-5 max-lg:grid-cols-4 gap-4 overflow-y-auto">
            {featuredBrands.map((brand) => (
              <Link
                key={brand.name || brand._id}
                to={`/brand/${brand._id}`}
                className="relative flex items-center justify-center flex-shrink-0"
              >
                {/* <div className="absolute inset-2 bg-hotPink bg-opacity-50 blur-2xl" /> */}
                <img
                  src={brand.logoUrl}
                  alt={brand.name}
                  loading="lazy"
                  className="size-20 object-contain object-center z-[1]"
                />
              </Link>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default BrandList;

Tab.propTypes = {
  children: PropTypes.node.isRequired,
  selected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

SearchBar.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

AlphabetNavigation.propTypes = {
  alphabetList: PropTypes.arrayOf(PropTypes.string).isRequired,
  scrollToHeading: PropTypes.func.isRequired,
};
