import { AnimatePresence, motion } from "framer-motion";
import { useModal } from "../contexts/modalContext";
import { createPortal } from "react-dom";

const modalVariants = {
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      ease: "easeOut",
      duration: 0.15,
    },
  },
  hidden: {
    opacity: 0,
    scale: 0.75,
    transition: {
      ease: "easeIn",
      duration: 0.15,
    },
  },
};

const Backdrop = ({ children, onClick }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      onClick={onClick}
      className="MODAL-BACKDROP fixed z-50 inset-0 bg-black bg-opacity-80 flex justify-center items-center overflow-auto"
    >
      {children}
    </motion.div>
  );
};

const Modal = () => {
  const { closeModal, modalContent, isModalOpen } = useModal();

  return createPortal(
    <AnimatePresence initial={false}>
      {isModalOpen && (
        <Backdrop onClick={closeModal}>
          <motion.div
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={modalVariants}
            onClick={(e) => e.stopPropagation()}
            className="MODAL relative"
          >
            {modalContent}
          </motion.div>
        </Backdrop>
      )}
    </AnimatePresence>,
    document.getElementById("modal")
  );
};

export default Modal;
