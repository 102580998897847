import { useState, useEffect, useCallback } from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

const Slider = ({
  children: slides,
  dots = false,
  navigation = false,
  autoSlide = false,
  autoSlideInterval = 3000,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const prev = useCallback(() => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? slides.length - 1 : prevSlide - 1
    );
  }, [slides.length]);

  const next = useCallback(() => {
    setCurrentSlide((prevSlide) =>
      prevSlide === slides.length - 1 ? 0 : prevSlide + 1
    );
  }, [slides.length]);

  useEffect(() => {
    if (!autoSlide) return;
    const slideInterval = setInterval(next, autoSlideInterval);
    return () => clearInterval(slideInterval);
  }, [autoSlide, autoSlideInterval, next]);

  const carouselStyle = {
    width: `${slides.length * 100}%`,
    transform: `translateX(-${(currentSlide * 100) / slides.length}%)`,
  };

  return (
    <div className="CAROUSEL-CONTAINER overflow-hidden relative">
      <div
        className="CAROUSEL-TRACK flex transition-transform ease-out duration-500"
        style={carouselStyle}
      >
        {slides}
      </div>

      {navigation && (
        <div className="absolute inset-0 flex items-center justify-between p-4">
          <button
            onClick={prev}
            className="p-1 rounded-full shadow bg-white/80 text-gray-800 hover:bg-white"
          >
            <FiChevronLeft size={24} />
          </button>
          <button
            onClick={next}
            className="p-1 rounded-full shadow bg-white/80 text-gray-800 hover:bg-white"
          >
            <FiChevronRight size={24} />
          </button>
        </div>
      )}

      {dots && (
        <div className="absolute bottom-4 right-1/2 translate-x-1/2">
          <div className="flex items-center justify-center gap-2">
            {slides.map((_, i) => (
              <button
                onClick={() => setCurrentSlide(i)}
                className={`
                  transition-all size-2 shadow bg-white rounded-full
                ${
                  currentSlide === i
                    ? "size-3"
                    : "bg-opacity-50 hover:bg-opacity-80"
                }
            `}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Slider;
