import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useUser } from "./userContext";
import { useToast } from "./toastContext";
import {
  addItemsToWishlist,
  getWishlistItems,
  removeItemsFromWishlist,
} from "../api/wishlistApis";

const WishlistContext = createContext();

export const WishlistProvider = ({ children }) => {
  const { user } = useUser();
  const toast = useToast();
  const [wishlistItems, setWishlistItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    console.log("The wishlist: " + JSON.stringify(wishlistItems));
  }, [wishlistItems]);

  const fetchWishlist = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const cacheKey = `wishlist_${user?._id}`;
      const cachedData = sessionStorage.getItem(cacheKey);
      if (cachedData) {
        setWishlistItems(JSON.parse(cachedData));
      } else {
        const response = await getWishlistItems();
        if (response?.data?.productIdList) {
          sessionStorage.setItem(
            cacheKey,
            JSON.stringify(response.data.productIdList)
          );
          setWishlistItems(response.data.productIdList);
        }
      }
    } catch (error) {
      console.error("Error fetching wishlist items", error);
      setError("Failed to fetch wishlist items. Please try again.");
    } finally {
      setLoading(false);
    }
  }, [user]);

  const addToWishlist = useCallback(
    async (productId) => {
      if (!user) {
        toast.error("You need to be logged in to use the wishlist.");
        return;
      }

      setLoading(true);
      setError(null);
      try {
        const response = await addItemsToWishlist(productId);

        if (response.status) {
          sessionStorage.removeItem(`wishlist_${user._id}`);
          fetchWishlist();
          console.log(response.data);
        }
      } catch (error) {
        console.error("Error adding to wishlist", error);
        setError("Failed to add item to wishlist. Please try again.");
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line
    [user, fetchWishlist]
  );

  const removeFromWishlist = useCallback(
    async (productId) => {
      if (!user) {
        toast.error("You need to be logged in to use the wishlist.");
        return;
      }

      setLoading(true);
      setError(null);
      try {
        const response = await removeItemsFromWishlist(productId);

        if (response.status) {
          sessionStorage.removeItem(`wishlist_${user._id}`);
          fetchWishlist();
          console.log(response.data);
        }
      } catch (error) {
        console.error("Error removing from wishlist", error);
        setError("Failed to remove item from wishlist. Please try again.");
      } finally {
        setLoading(false);
      }
    },
    // eslint-disable-next-line
    [user, fetchWishlist]
  );

  const isWished = (productId) => {
    return wishlistItems.some((item) => item._id === productId);
  };

  useEffect(() => {
    if (user) {
      fetchWishlist();
    }
  }, [user, fetchWishlist]);

  return (
    <WishlistContext.Provider
      value={{
        wishlistItems,
        addToWishlist,
        removeFromWishlist,
        isWished,
        loading,
        error,
      }}
    >
      {children}
    </WishlistContext.Provider>
  );
};

export function useWishlist() {
  return useContext(WishlistContext);
}
