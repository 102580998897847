import axiosInstance from "./axiosInstance";

const getAllBrands = async () => {
  const response = await axiosInstance.get("/getBrand");
  return response.data?.brandList;
};

const getBrandsBasedOnCategory = async (categoryId) => {
  const response = await axiosInstance.get(
    `/brandBasedOnCategory/${categoryId}`
  );
  return response.data?.brandDetails;
};

const getBrandsBasedOnProductType = async (productTypeId) => {
  const response = await axiosInstance.get(
    `/getBrandsBasedOnProductType/${productTypeId}`
  );
  return response.data?.brandList;
};

const getAllFeaturedBrands = async () => {
  const response = await axiosInstance.get("/getAllFeaturedBrand");
  return response.data?.brandDetails;
};

const getAllUtsavFeaturedBrands = async () => {
  const response = await axiosInstance.get("/getAllUtsavFeaturedBrand");
  return response.data?.productDetails;
};

const getAllTopSellingBrands = async () => {
  const response = await axiosInstance.get("/getAllTopSellingBrand");
  return response.data;
};

const getBrand = async (brandId) => {
  const response = await axiosInstance.get(`/getBrandById/${brandId}`);
  return response.data?.brandDetails;
};

export {
  getAllBrands,
  getBrandsBasedOnCategory,
  getBrandsBasedOnProductType,
  getAllFeaturedBrands,
  getAllUtsavFeaturedBrands,
  getAllTopSellingBrands,
  getBrand,
};
