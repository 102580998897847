import { FaCircleCheck } from "react-icons/fa6";
import { BsInfoCircleFill } from "react-icons/bs";
import { IoIosWarning, IoMdClose } from "react-icons/io";
import { MdError } from "react-icons/md";
import { useToast } from "../contexts/toastContext";
import { useCallback, useEffect, useRef } from "react";
import { AnimatePresence, motion } from "framer-motion";

const toastTypes = {
  success: {
    icon: <FaCircleCheck />,
    iconClass: "text-success",
    progressBarClass: "bg-success",
  },
  info: {
    icon: <BsInfoCircleFill />,
    iconClass: "text-info",
    progressBarClass: "bg-info",
  },
  warning: {
    icon: <IoIosWarning />,
    iconClass: "text-warning",
    progressBarClass: "bg-warning",
  },
  error: {
    icon: <MdError />,
    iconClass: "text-error",
    progressBarClass: "bg-error",
  },
};

const Toast = ({ message, type, id, duration = 4 }) => {
  const { icon, iconClass, progressBarClass } = toastTypes[type];
  const toast = useToast();
  const timerId = useRef(null);

  const handleDismiss = useCallback(() => {
    toast.remove(id);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    timerId.current = setTimeout(() => {
      handleDismiss();
    }, duration * 1000);

    return () => {
      clearTimeout(timerId.current);
    };
  }, [handleDismiss, duration]);

  return (
    <motion.li
      initial={{ opacity: 0, y: 50, scale: 0.3 }}
      animate={{ opacity: 1, y: 0, scale: 1 }}
      exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}
      className="TOAST h-max w-full p-3 pr-6 bg-white relative flex justify-start items-start gap-2 rounded-md select-none overflow-hidden shadow-primary"
    >
      <span className={`ICON mt-[2px] ${iconClass}`}>{icon}</span>
      <p className="MESSAGE text-sm leading-tight first-letter:capitalize">
        {message}
      </p>
      <button type="button" onClick={handleDismiss}>
        <IoMdClose className="absolute right-3 top-1/2 -translate-y-1/2 text-primary hover:text-secondary" />
      </button>
      <motion.span
        initial={{ width: "100%" }}
        animate={{ width: "0%" }}
        transition={{ duration: duration }}
        className={`h-1 absolute bottom-0 left-0 right-0 ${progressBarClass}`}
      />
    </motion.li>
  );
};

const ToastsContainer = ({ toasts }) => {
  return (
    <AnimatePresence initial={false}>
      {toasts.length > 0 && (
        <ul className="TOASTS-CONTAINER max-w-96 w-full flex flex-col-reverse gap-3 p-3 fixed top-0 right-0 z-[60]">
          {toasts.map((toast) => (
            <Toast key={toast.id} {...toast} />
          ))}
        </ul>
      )}
    </AnimatePresence>
  );
};

export default ToastsContainer;
