import Spinner from "./Spinner";

const Loading = () => {
  return (
    <div className="absolute inset-0 bg-white bg-opacity-50 flex items-center justify-center">
      <Spinner />
    </div>
  );
};

export default Loading;
