import AnimatedRoutes from "./components/AnimatedRoutes";
import Loader from "./components/Loader";
import { useUser } from "./contexts/userContext";

const App = () => {
  const { isLoading } = useUser();

  return isLoading ? <Loader /> : <AnimatedRoutes />;
};

export default App;
