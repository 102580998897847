import { getCookie } from "../utils/cookie";
import axiosInstance from "./axiosInstance";

const addNewAddress = (address) => {
  const token = getCookie("accessToken");
  return axiosInstance.post("/addAddress", address, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
};

const getAddresses = () => {
  const token = getCookie("accessToken");
  return axiosInstance.get("/getAddressOfUser", {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
};

const updateExistingAddress = (addressId, address) => {
  const token = getCookie("accessToken");
  return axiosInstance.post(
    "updateAddressOfUser",
    { addressId, ...address },
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
};

const deleteAddress = (addressId) => {
  const token = getCookie("accessToken");
  return axiosInstance.get(`/deleteAddress/${addressId}`, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
};

const setAddressAsDefault = (addressId) => {
  const token = getCookie("accessToken");
  return axiosInstance.post(
    "/setDefaultAddress",
    { addressId },
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
};

export {
  addNewAddress,
  getAddresses,
  updateExistingAddress,
  deleteAddress,
  setAddressAsDefault,
};
