const Skeleton = ({ overall = "normal" }) => {
  return (
    <div
      className={`size-full animate-pulse ${
        overall === "dark"
          ? "bg-darkGray"
          : overall === "normal"
          ? "bg-mainGray"
          : overall === "light" && "bg-lightGray"
      }`}
    />
  );
};

export default Skeleton;
