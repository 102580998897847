import { useQuery } from "@tanstack/react-query";
import { getAllFashionBlogs } from "../api/fashionApis";

const useFashionBlogs = () => {
  return useQuery({
    queryKey: ["fashionBlogs"],
    queryFn: getAllFashionBlogs,
    staleTime: 1000 * 60 * 5,
  });
};

export { useFashionBlogs };
