import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const useScrollToTop = (behavior = "instant") => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: behavior });
  }, [pathname, behavior]);
};

export default useScrollToTop;
