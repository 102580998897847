import { useState } from "react";

export default function useForm(initialData, onSubmit) {
  const [formData, setFormData] = useState(initialData);

  const clearForm = () => setFormData(initialData);

  const handleChange = (e) => {
    const { name, value, type, files, checked } = e.target;

    if (type === "file") {
      // Handle file inputs
      setFormData({ ...formData, [name]: files });
    } else if (type === "checkbox") {
      // Handle checkbox click
      setFormData({ ...formData, [name]: checked });
    } else {
      // For other inputs, update the formData state as usual
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return { formData, setFormData, handleChange, handleSubmit, clearForm };
}
