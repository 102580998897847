import { getCookie } from "../utils/cookie";
import axiosInstance from "./axiosInstance";

const addWalletBalance = (amount, description) => {
  const token = getCookie("accessToken");
  return axiosInstance.post(
    "/addBalance",
    { amount, description },
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
};

const getWalletBalance = () => {
  const token = getCookie("accessToken");
  return axiosInstance.get("/getBalance", {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
};

export { addWalletBalance, getWalletBalance };
