import { createContext, useContext, useEffect, useState } from "react";

const RecentlyViewedContext = createContext();

export const RecentlyViewedProvider = ({ children }) => {
  const [recentProducts, setRecentProducts] = useState(() => {
    const storedProducts = localStorage.getItem("recentProducts");
    return storedProducts ? JSON.parse(storedProducts) : [];
  });

  const addProduct = (product) => {
    setRecentProducts((prev) => {
      // Avoid duplicates and limit to a certain number, e.g., 10
      const updatedList = [
        product,
        ...prev.filter((p) => p._id !== product._id),
      ];
      return updatedList.slice(0, 10);
    });
  };

  useEffect(() => {
    if (recentProducts.length > 0) {
      localStorage.setItem("recentProducts", JSON.stringify(recentProducts));
    }
  }, [recentProducts]);

  return (
    <RecentlyViewedContext.Provider value={{ recentProducts, addProduct }}>
      {children}
    </RecentlyViewedContext.Provider>
  );
};

export const useRecentlyViewed = () => {
  return useContext(RecentlyViewedContext);
};
